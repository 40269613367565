import React, { useState, useEffect, useCallback } from "react";
import NoteComponent from "./components/NoteComponent";
import "./App.css";
import { Note } from "./types";

function App() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [notes, setNotes] = useState<Note[]>([]);
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const [isAddingNote, setIsAddingNote] = useState<boolean>(false);

  const fetchNotes = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/notes`);
      if (!response.ok) {
        throw new Error("Failed to fetch notes");
      }
      const data = await response.json();
      console.log(data);

      data.forEach((note: any) => {
        note.labels = note.labels.split(",");
      });

      setNotes(data);
    } catch (error) {
      console.error(error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  useEffect(() => {
    const intervalId = setInterval(fetchNotes, 1000 * 3);

    return () => clearInterval(intervalId);
  }, [fetchNotes]);

  window.onfocus = () => {
    fetchNotes();
  };

  const handleAddNote = () => {
    setIsAddingNote(true);
    setEditingNote({
      id: -1,
      title: "",
      content: "",
      labels: [],
    });
  };

  const handleDeleteNote = async (id: number) => {
    try {
      const response = await fetch(`${apiUrl}/notes/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete note");
      }
      fetchNotes();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateNote = async (note: Note) => {
    try {
      const url =
        note.id === -1 ? `${apiUrl}/notes` : `${apiUrl}/notes/${note.id}`;
      const method = note.id === -1 ? "POST" : "PUT";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: note.title,
          content: note.content,
          labels: note.labels,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to edit note");
      }
      fetchNotes();
      setIsAddingNote(false);
      setEditingNote(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseNoteForm = () => {
    setIsAddingNote(false);
    setEditingNote(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="title">Note</div>
      </header>
      <main>
        <div id="content">
          <div>
            <div onClick={handleAddNote}>
              <i className="bi bi-plus-square-fill ajouter-note"></i>
            </div>
          </div>
          <div className="notes">
            {isAddingNote && (
              <NoteComponent
                note={editingNote!}
                onDeleteNote={() => handleCloseNoteForm()}
                editable
                onSaveNote={handleUpdateNote}
                focusTitle
              />
            )}
            {notes.slice().reverse().map((note) => (
              <NoteComponent
                key={note.id}
                note={note}
                onDeleteNote={handleDeleteNote}
                onSaveNote={handleUpdateNote}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
