import React, { useEffect, useRef, useState } from "react";
import "./NoteComponent.scss";
import { Note } from "../types";

interface Props {
  note: Note;
  onDeleteNote: (id: number) => void;
  editable?: boolean;
  onSaveNote?: (note: Note) => void;
  focusTitle?: boolean;
}

const NoteComponent: React.FC<Props> = ({
  note,
  onDeleteNote,
  editable = false,
  onSaveNote,
  focusTitle = false,
}) => {
  const [editedNote, setEditedNote] = useState<Note>(note);
  const [isEditing, setIsEditing] = useState(editable);
  const [newLabels, setNewLabels] = useState<string[]>(
    note.labels.filter((label) => label.trim() !== "")
  );
  const [newLabel, setNewLabel] = useState<string>("");
  const titleRef = useRef<HTMLDivElement>(null);
  const newLabelRef = useRef<HTMLDivElement>(null);
  const [isThereAnyLabel, setIsThereAnyLabel] = useState<boolean>(false);

  useEffect(() => {
    if (isEditing && titleRef.current) {
      titleRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      if (selection) {
        range.selectNodeContents(titleRef.current);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
      // const parentElement = titleRef.current.parentElement; // To detect if the element is completely in view
      // if (parentElement) {
      //   const parentRect = parentElement.getBoundingClientRect();
      //   const isInView =
      //     parentRect.top >= 0 &&
      //     parentRect.left >= 0 &&
      //     parentRect.bottom <=
      //       (window.innerHeight || document.documentElement.clientHeight) &&
      //     parentRect.right <=
      //       (window.innerWidth || document.documentElement.clientWidth);
      //   if (!isInView) {
      //     parentElement.scrollIntoView({ behavior: "smooth" });
      //   }
      // }
    }
    setIsThereAnyLabel(newLabels[0] !== "");
  }, [isEditing]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLDivElement>) => {
    const newTitle = event.target.textContent || "";
    setEditedNote({
      ...editedNote,
      title: newTitle,
    });
  };

  const handleContentChange = (event: React.ChangeEvent<HTMLDivElement>) => {
    const newContent = event.target.textContent || "";
    setEditedNote({
      ...editedNote,
      content: newContent,
    });
  };

  useEffect(() => {
    if (newLabel.trim() !== "") {
      setNewLabels((prevLabels) => [...prevLabels, newLabel].filter((label) => label.trim() !== ""));
      setNewLabel("");
    }
    setIsThereAnyLabel(newLabels[0] !== "");
  }, [newLabel]);

  const handleAddLabel = () => {
    setNewLabel(newLabelRef.current?.innerText || "");
  };

  const handleEnterLabel = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddLabel();
    }
  };

  const handleDeleteLabel = (index: number) => {
    const updatedLabels = newLabels.filter((_, i) => i !== index);
    setNewLabels(updatedLabels);
    setIsThereAnyLabel(newLabels[0] !== "");
  };

  const handleSaveNote = () => {
    if (
      onSaveNote &&
      (editedNote.title.trim() !== "" || editedNote.content.trim() !== "")
    ) {
      onSaveNote({
        ...editedNote,
        labels: newLabels,
      });
      setIsEditing(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedNote(note);
    setNewLabels(note.labels || []);
    setIsEditing(false);
  };

  return (
    <div className="note" data-editable={isEditing} data-new-note={focusTitle}>
      <div
        className="note-title"
        contentEditable={isEditing}
        onBlur={handleTitleChange}
        ref={titleRef}
        spellCheck={false}
      >
        {editedNote.title}
      </div>
      <div
        className="note-content"
        contentEditable={isEditing}
        spellCheck={false}
        onBlur={handleContentChange}
      >
        {editedNote.content}
      </div>
      <div className="note-labels">
        {isThereAnyLabel &&
          newLabels.map((label: string, index: number) => (
            <span
              className="note-label"
              key={index}
              onClick={() => isEditing && handleDeleteLabel(index)}
            >
              {label}
              <div className="note-label-click-delete">
                <i className="bi bi-x"></i>
              </div>
            </span>
          ))}
        {isEditing && (
          <div className="note-add-container">
            <div
              className="note-add-label"
              contentEditable
              spellCheck={false}
              onKeyDown={handleEnterLabel}
              ref={newLabelRef}
            >
              {newLabel}
            </div>
            <div className="note-add-button" onClick={handleAddLabel}>
              <i className="bi bi-plus-square-fill"></i>
            </div>
          </div>
        )}
      </div>
      {isEditing ? (
        <div className="note-actions">
          <div className="actions-wrapper">
            <div className="note-action" onClick={handleSaveNote}>
              <i className="bi bi-save note-save"></i>
            </div>
            {!focusTitle && (
              <div className="note-action" onClick={handleCancelEdit}>
                <i className="bi bi-x-square note-cancel"></i>
              </div>
            )}
            <div className="note-action" onClick={() => onDeleteNote(note.id)}>
              <i className="bi bi-trash note-delete"></i>
            </div>
          </div>
        </div>
      ) : (
        <div className="note-actions">
          <div className="actions-wrapper">
            <div className="note-action" onClick={() => setIsEditing(true)}>
              <i className="bi bi-pencil-square note-edit"></i>
            </div>
            <div className="note-action" onClick={() => onDeleteNote(note.id)}>
              <i className="bi bi-trash note-delete"></i>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoteComponent;
